.slideshow {
    z-index:0;
    display:block;
    margin-left:auto;
    margin-right:auto;
    width:70%;
    height:auto;
    padding-top: 5%;
}



@media only screen and (max-width: 800px) {
    .slideshow {
        width:90%;
    }
  }