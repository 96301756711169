html, body {
 width:100%;
 margin-left:0;
 margin-right:0;
 overflow-x:hidden;
}

#icon1 {
  width:13em;
  height:auto;
  margin-left:auto;
  margin-right:auto;
  padding-top:20px;
}

#icon2 {
  width:15em;
  height:auto;
  margin-left:auto;
  margin-right:auto;
}
#icon3 {
  width:17em;
  height:auto;
  margin-left:auto;
  margin-right:auto;
}

.App {
  /* text-align: center; */
  overflow:hidden;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.card1 {
  width: 35em;
  margin-left:auto;
  margin-right:auto;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* justify-content: center; */
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/*-------------------------------------*/
* {
  box-sizing: border-box;
}

.text-dchealth {
  color:#769c2e;
}

.App {
  /* max-width: 420px; */
  margin: 0 auto;
}

.accolades {
  min-height:1200px;
  height:100%;
}

.accordion {
  width:90%;
  margin-left:auto;
  margin-right:auto;
}

.iconher {
  MAX-width:600px;
  height:auto;
  margin-top: -60px;
  margin-left:-250px;
}

.testimonial1 {
  width:90%;
  height:auto;
}

p {
  /* text-align: center; */
  font-weight: 500;
}

.dacotapic {
  width:300px;
  height:auto;
  border-radius:150px;
  border:solid 2px #99c93c;
}

.homepic {
  width:100%;
  height:auto;
}

.workspacepic {
  width:100%;
  height:auto;
}

.round {
  border-radius:15px!important;
}

.pic {
  box-shadow:0px 3px 2px 1px #00000014;
  margin:15px;
}

.text-heavy {
  font-weight: 600;
}

.text-pink {
  color:#fa243c;
}

.igdiv {
  justify-content: center;
}

.field {
  box-shadow: 1px 1px 3px #000000 inset, 
            -1px -1px 3px #7e7e7e inset;
  color:white;
  background-color:rgb(41, 41, 41);
}

.contactbkg {
  /* background-image:url('./images/concert.jpg'); */
  /* height:; */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 35%;
}

.tkbkg {
  /* background-image:url('./images/tkabout.jpg'); */
  height:1800px;
  background-size: cover;
  background-repeat:no-repeat;
}

.bkg {
  /* background-image:url('./images/pattern.png'); */
  background-repeat: repeat;
  /* background-size: ; */
  background-attachment: fixed;
}

.toplayer {
  z-index: 3;
}

.secondlayer {
  z-index: 2;
}

.logo {
  display:block;
  margin-left:auto;
  margin-right:auto;
  width:60%;
  height:auto;
  
}

.infotop {
  z-index: 1;
  position:relative;
}

.pbkg {
  z-index:0;
  display:block;
  margin-left:auto;
  margin-right:auto;
  width:90%;
  height:auto;
}

.igbkg {
  margin-left:auto;
  margin-right:auto;
  /* width:50%; */
}

.topitem {
  margin-top:100px;
}

input[type=text], input[type=email], select, textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 6px;
  margin-bottom: 16px;
  resize: vertical;
}

input[type=submit] {
  background-color: #4CAF50;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

input[type=submit]:hover {
  background-color: #45a049;
}

.homeframe {
  width:100%;
  height:500px;
  margin-left: auto;
  margin-right: auto;
}

.carousel-inner {
  border-radius:10px;
}

.carousel1 {
  width:90%;
  margin-left:auto;
  margin-right:auto;
}

.container {
  border-radius: 5px;
  background-color: #f2f2f2;
  padding: 20px;
  box-shadow: inset 5px 5px 12px #58585840, 
            inset -5px -5px 12px #58585840;
}

/* unvisited link */
a:link {
  color: #99c93c;
}

/* visited link */
a:visited {
  color: rgb(139 183 55);
}

/* mouse over link */
a:hover {
  color: rgb(255, 0, 0);
  text-decoration: none;
}

/* selected link */
a:active {
  color: rgb(255, 255, 255);
}

/* ---sidebar---*/
.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  min-height: 100vh !important;
  z-index: 100;
  padding: 48px 0 0;
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
}

#sidebar-wrapper{
  min-height: 100vh !important;
  width: 100vw;
  margin-left: -1rem;
  -webkit-transition: margin .25s ease-out;
  -moz-transition: margin .25s ease-out;
  -o-transition: margin .25s ease-out;
  transition: margin .25s ease-out;
}

#sidebar-wrapper .sidebar-heading {
  padding: 0.875rem 1.25rem;
  font-size: 1.2rem;
}

#page-content-wrapper {
  min-width: 0;
  width: 100%;
}

@media only screen and (max-width: 800px) {
  .fulltext {
      display:none;
  }
  .smalltext {
    display:block;
  }
}

@media only screen and (min-width: 801px) {
  .fulltext {
      display:block;
  }
  .smalltext {
    display:none;
  }
}

@media only screen and (max-width: 800px) {
  .iconher {
    MAX-width:400px;
    height:auto;
    margin-top: -10px;
    margin-left:50px;
  }
}