.App-header {
    background-color: #e6e6e6;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
.bannermain { 

  z-index:0;
}

.overflow {
  /* overflow-x: hidden; */
}

#banner  {
  z-index:1000;
  background-color:#e8e8e8;
}

/* .footer1 {
  bottom: ;
} */
.headerpic {
  /* width:100%; */
  height:auto;
  top:0;
}

h1 {
  text-align:center;
  padding:20px;
}

.fixed {
  position: fixed;
  top: 0;
  z-index: 98;
}

@media only screen 
and (min-device-width : 300px) 
and (max-device-width : 750px) {
  .header1 {
    display:none;
  }
  .header1b {
    display:block;
  }
  .header2 {
    display:none;
  }
  .header3 {
    display:none;
  }
  .topitem {
    margin-top: 12%;
  }
}

@media only screen and (max-width: 800px) {
  .header1 {
    display:block;
  }
  .header1b {
    display:none;
  }
  .header2 {
    display:none;
  }
  .header3 {
    display:none;
  }
  .topitem {
    margin-top: 12%;
  }
}

@media only screen and (min-width: 801px) and (max-width: 1280px) {
  .header1 {
    display:none;
  }
  .header1b {
    display:none;
  }
  .header2 {
    display:block;
  }
  .header3 {
    display:none;
  }
  .topitem {
    margin-top: 7%;
  }
}

@media only screen and (min-width: 1281px) {
  .header1 {
    display:none;
  }
  .header1b {
    display:none;
  }
  .header2 {
    display:none;
  }
  .header3 {
    display:block;
  }
  .topitem {
    margin-top: 7%;
  }
}