.footer {
  position: initial;
  width: 100%;
  margin-top: 10px;
  bottom: 10px;
  line-height: 10px;
  display: inline-block;
  z-index:1;
  text-align:center;
  }
