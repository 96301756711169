/* bar when in*/
._2Q5t5 {
    width: 50px;
    background-color: rgba(255, 255, 255, 0);
    color:#000;
}
._13dNw {
    transition: all 0s;
    z-index: 99;
}

.logotext {
    padding:10px;
}

.fcredits {
    font-size:x-small;
}

._VrCvP {
    background-color: #000;
}

._2_oI1 {
    z-index:50;
}

.cover2 {
   animation-name: overcoverhide;
   animation-duration:1.5s;
   animation-delay: 1.4s;
   /* animation-direction: reverse; */
   z-index:-1;
}

@keyframes overcoverhide {
    0% {z-index:1;}
    100% {z-index:-1;}
}

.covered {
    position:absolute;
    width:250px;
    height:100%;
    background-color: #000;
    animation-name: slowopen;
    animation-duration: 1.3s;
    animation-delay:1s;
    animation-direction: reverse;
  }
  
@keyframes slowopen {
    0%   {display:none;}
    100% {display:block;}
  }